// Transition
@mixin transitionSlow{
	-webkit-transition: all 0.8s;
	-ms-transition: all 0.8s;
	transition: all 0.8s;
}
@mixin transitionMedium{
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}
@mixin durationMedium{
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}
@mixin transitionFast{
	-webkit-transition: all 0.2s;
	-ms-transition: all 0.2s;
	transition: all 0.2s;
}
@mixin transformScale{
	transform:scale(1);
	-moz-transform:scale(1);
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	-o-transform:scale(1);
}
@mixin transformScaleXS{
	transform:scale(0.8);
	-moz-transform:scale(0.8);
	-webkit-transform:scale(0.8);
	-ms-transform:scale(0.8);
	-o-transform:scale(0.8);
}
@mixin transformScaleSM{
	transform:scale(1.2);
	-moz-transform:scale(1.2);
	-webkit-transform:scale(1.2);
	-ms-transform:scale(1.2);
	-o-transform:scale(1.2);
}
@mixin transformScaleMD{
	transform:scale(1.5);
	-moz-transform:scale(1.5);
	-webkit-transform:scale(1.5);
	-ms-transform:scale(1.5);
	-o-transform:scale(1.5);
}