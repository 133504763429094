
#loading-icon-bx {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $light;
    z-index: 999999999;
	
	.loading-inner{
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		div{
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			
			&.load-one{
				left: 0%;
				top: 0%;
				animation: load-rotate-one 1.15s linear infinite;
				-o-animation: load-rotate-one 1.15s linear infinite;
				-ms-animation: load-rotate-one 1.15s linear infinite;
				-webkit-animation: load-rotate-one 1.15s linear infinite;
				-moz-animation: load-rotate-one 1.15s linear infinite;
				border-bottom: 5px solid $primary;
			}
			&.load-two{
				right: 0%;
				top: 0%;
				animation: load-rotate-two 1.15s linear infinite;
				-o-animation: load-rotate-two 1.15s linear infinite;
				-ms-animation: load-rotate-two 1.15s linear infinite;
				-webkit-animation: load-rotate-two 1.15s linear infinite;
				-moz-animation: load-rotate-two 1.15s linear infinite;
				border-right: 5px solid $primary;
			}
			&.load-three{
				right: 0%;
				bottom: 0%;
				animation: load-rotate-three 1.15s linear infinite;
				-o-animation: load-rotate-three 1.15s linear infinite;
				-ms-animation: load-rotate-three 1.15s linear infinite;
				-webkit-animation: load-rotate-three 1.15s linear infinite;
				-moz-animation: load-rotate-three 1.15s linear infinite;
				border-top: 5px solid $primary;
			}
		}
	}
}

@keyframes load-rotate-one {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}
@-o-keyframes load-rotate-one {
	0% {
		-o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}
@-ms-keyframes load-rotate-one {
	0% {
		-ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}
@-webkit-keyframes load-rotate-one {
	0% {
		-webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}
@-moz-keyframes load-rotate-one {
	0% {
		-moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}
@keyframes load-rotate-two {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}
@-o-keyframes load-rotate-two {
	0% {
		-o-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-o-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}
@-ms-keyframes load-rotate-two {
	0% {
		-ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}
@-webkit-keyframes load-rotate-two {
	0% {
		-webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}
@-moz-keyframes load-rotate-two {
	0% {
		-moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}
@keyframes load-rotate-three {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}
@-o-keyframes load-rotate-three {
	0% {
		-o-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-o-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}
@-ms-keyframes load-rotate-three {
	0% {
		-ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}
@-webkit-keyframes load-rotate-three {
	0% {
		-webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}
@-moz-keyframes load-rotate-three {
	0% {
		-moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}