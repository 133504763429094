/* ==================
	8.16 Pricing Table
=================== */
.table-wrapper{
	position: relative;
	padding: 30px;
	background:	#fff;	
	border: 2px solid #e4e3e4;
	@include transitionMedium;
	border-radius:$border-radius-base;
	
	// Table Head
	.table-head{
		margin-bottom: 30px;
		.table-title{
			margin-bottom: 25px;
			text-align: center;
			.sub-title{
				font-size: 16px;
				font-weight: 500;
				margin-bottom:0;
			}
		}
		.table-price{
			display:flex;
			justify-content: center;
			align-items: center;
			sup{
				font-size: 26px;
				font-weight: bold;
				margin-right: 5px;
				color: $headings-color;
			}
			h6{
				font-size: 52px;
				font-weight: 700;
				margin-bottom:0;
			}
			p{
				margin: 0 0 0 5px;
				font-size: 14px;
			}
			@include respond('phone-land'){
				sup{
					font-size: 14px;
				}
				h6{
					font-size: 36px;
				}
			}
		}
	}
	
	// Table Body
	.table-body{
		margin-bottom:40px;
		.title{
			font-size: 16px;
			margin-bottom: 30px;
			text-align: center;
			@include respond('phone-land'){
				font-size:14px;
			}
		}
		.table-features{
			margin-bottom:0;
			li{
				font-size: 15px;
				position: relative;
				line-height: 20px;
				margin-bottom: 15px;
				padding-left: 34px;
				font-weight: 500;
				&:before{
					content: "\f00c";
					font-size: 24px;
					font-family: 'Line Awesome Free';
					font-weight: 900;
					line-height: 25px;
					color: #11a435;
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}
	}
	&:hover{		
		border:2px solid $primary;
		box-shadow:0 5px 20px rgba(0,98,255,0.2);
		margin-top:-10px;
		.table-head{
			.table-price {
				sup,
				h6{
					color: $primary;
				}
			}
		}
		.table-footer{
			.btn{
				background-color:$primary;
				color:$white;
			}
		}
	}

}
 
// Table Switch
.table-switch{
	display: flex;
    justify-content: center;
	align-items: center;
	.form-check-input{
		width: 60px;
		height: 30px;
		box-shadow:0  3px 10px 0 rgba(0,98,252,0.2);
		background-color:$primary;		
	}
	&:before{
		height: 22px;
		width: 22px;
	}
	label{
		margin:0 55px 0 14px;
		font-size:16px;
		color:#666;
		cursor: pointer;
		font-weight:500;
	}
}