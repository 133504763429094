.line-clamp{
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
}
.icon-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.feature-container{
    height: 100%;
}